<template>
  <div id="page-user-list">
    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">
        <form style="min-width: 320px;">
          <h1>สร้างรายการ AKPAY</h1>

          <div class="my-5">
            <vs-input  class="w-full mt-4" label="username" v-model="username"
            type="text" name="username" />
            <vs-input  class="w-full mt-4" label="จำนวนเงิน" v-model="amount"
              type="number" name="amount" />
          </div>

          <vs-button
            :disabled="!$store.state.AppActiveUser.permissions.payment.action"
            color="primary"
            type="filled"
            @click="() => generateQR()"
          >
            {{ result ? 'สร้างรายการใหม่' : 'สร้างรายการ' }}
          </vs-button>


          <div v-if="result && result.success">
              <vs-divider />
              <vs-input  class="w-full mt-4" label="username" v-model="result.data.username"
              type="text" name="username" readonly />
              <vs-input  class="w-full mt-4" label="จำนวนเงิน" v-model="result.data.amount"
                type="number" name="amount" readonly />
              <vs-input
                class="w-full my-2"
                label="Transaction ID"
                readonly
                v-model="result.data.qrCodeData.tx_id"
                type="text"
                name="amount"
              />
              <vs-input
                class="w-full my-2"
                label="MOID"
                readonly
                v-model="result.data.qrCodeData.order_id"
                type="text"
                name="amount"
              />
              <a targe="_blank" :href="result.data.qrCodeData.qrCode">
                <vs-button
                  type="filled"
                  color="success"
                  icon="payment"
                  >ชำระเงิน
                </vs-button>
              </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios'

export default {
  components: {
  },
  data () {
    return {
      amount: null,
      username: null,
      result: null
    }
  },
  computed: {

  },
  watch: {

  },
  async mounted () {

  },
  methods: {
    async generateQR () {
      try {
        const result = (await axios
          .post('/akpay/generate', {
            amount: this.amount,
            username: this.username,
            redirect_url: location.href
          })).data

        if (!result.success) {
          this.$vs.notify({
            time: 10000,
            color: 'danger',
            position: 'top-right',
            icon: 'error',
            title: result.code,
            text: result.message
          })
          return
        }

        this.$vs.notify({
          time: 10000,
          color: 'success',
          position: 'top-right',
          icon: 'check',
          title: result.code,
          text: result.message
        })

        // Setup data
        this.result = result

        // Clear input data
        this.clearData()
      } catch (err) {
        this.clearData()

        this.$vs.notify({
          time: 10000,
          color: 'success',
          position: 'top-right',
          icon: 'check',
          title: err.code,
          text: err.message
        })
      }
    },
    clearData () {
      this.amount = null
      this.username = null
    }
  }
}
</script>
